import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  DRepMetadata,
  IDelegateDRepResponse,
  ILoginRequestBody,
  ILogoutResponse,
  IMintDRepTokenRequestBody,
  IMintDRepTokenResponse,
  ISubmitTxRequestBody,
} from "@src/store/drep/types";
import environments from "@src/configs/environments";

export const DREP_PATH = "drep";

export const drepApi = createApi({
  reducerPath: DREP_PATH,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: environments.BASE_API_URL,
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.append("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDRepById: builder.query<DRepMetadata, string>({
      query: (id) => ({
        url: `/drep?id=${id}`,
        method: "GET",
      }),
    }),
    getDRepByName: builder.query<DRepMetadata, string>({
      query: (name) => ({
        url: `/drep?name=${name}`,
        method: "GET",
      }),
    }),
    mintDRepToken: builder.mutation<
      IMintDRepTokenResponse,
      IMintDRepTokenRequestBody
    >({
      query: (mintDRepTokenRequestBody) => ({
        url: `/register`,
        method: "POST",
        body: JSON.stringify(mintDRepTokenRequestBody),
      }),
    }),
    getDelegatedDRep: builder.query<IDelegateDRepResponse | null, string>({
      query: (stakeAddress) => ({
        url: `/vote/delegation?stakeAddress=${stakeAddress}`,
        method: "GET",
      }),
    }),
    submitDRepToken: builder.mutation<
      IMintDRepTokenResponse,
      ISubmitTxRequestBody
    >({
      query: (submitDrepTokenRequestBody) => ({
        url: `/submit`,
        method: "POST",
        body: JSON.stringify(submitDrepTokenRequestBody),
      }),
    }),
    dRepUnique: builder.query<boolean, string>({
      query: (name) => ({
        url: `/is-unique?name=${name}`,
        method: "GET",
      }),
    }),
    dRepLogin: builder.mutation<boolean, ILoginRequestBody>({
      query: (loginRequestBody) => ({
        url: `/login`,
        method: "POST",
        body: JSON.stringify(loginRequestBody),
      }),
    }),
    dRepLogout: builder.mutation<ILogoutResponse, void>({
      query: () => ({
        url: `/logout`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetDRepByIdQuery,
  useGetDRepByNameQuery,
  useLazyGetDRepByNameQuery,
  useLazyGetDRepByIdQuery,
  useMintDRepTokenMutation,
  useGetDelegatedDRepQuery,
  useLazyGetDelegatedDRepQuery,
  useSubmitDRepTokenMutation,
  useDRepLoginMutation,
  useDRepLogoutMutation,
  useLazyDRepUniqueQuery,
} = drepApi;
