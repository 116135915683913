import React from "react";

const CrossIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.125 11.875L11.875 28.125M11.875 11.875L28.125 28.125"
      stroke="#AAAAAA"
      strokeWidth="3.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CrossIcon;
