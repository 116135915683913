import { ToastId } from "@src/constants/toastId";
import { IConnectedWallet } from "@src/store/user/wallet";
import { bech32 } from "bech32";
import { CIP30Wallet } from "kuber-client";
import { toast } from "react-toastify";
import { PersistPartial } from "redux-persist/es/persistReducer";

async function getProvider(
  walletName: string,
  retries = 5,
  delay = 1000,
): Promise<CIP30Wallet | undefined> {
  let attempt = 0;

  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const providers = CIP30Wallet.listProviders();
      const provider = providers.find((prov) => prov.name === walletName);

      if (provider) {
        clearInterval(interval);
        try {
          const walletProvider = await provider.enable({
            extensions: [{ cip: 95 }],
          });
          resolve(walletProvider);
        } catch (error) {
          reject(error);
        }
      } else if (attempt >= retries) {
        clearInterval(interval);
        reject(new Error("Provider not found within the time limit."));
      }
      attempt++;
    }, delay);
  });
}

export async function getCip30Wallet(
  wallet: IConnectedWallet & PersistPartial,
): Promise<CIP30Wallet | undefined> {
  try {
    return await getProvider(wallet.name);
  } catch (error: any) {
    if (error.info && error.info.toLowerCase().includes("no account set")) {
      toast.error(`Wallet ${error}`, { toastId: ToastId.ERROR_TOAST });
    } else {
      console.error(`${error}`);
    }
  }
}

export function toCIP105DRep(drepId: string) {
  const decodedDRepId = bech32.decode(drepId, 100);
  const drepIdHex = Buffer.from(bech32.fromWords(decodedDRepId.words))
    .toString("hex")
    .slice(-56);
  const cip105DRepId = bech32.encode(
    "drep",
    bech32.toWords(Buffer.from(drepIdHex, "hex")),
  );
  return cip105DRepId;
}

export async function getWalletBalance(
  wallet: IConnectedWallet & PersistPartial,
) {
  const kuberClientWallet = await getCip30Wallet(wallet);
  const walletBalance = await kuberClientWallet?.calculateBalance();
  return walletBalance?.lovelace.toString();
}
