import { CIP30Wallet } from "kuber-client";
import { blake2bHash, hexToBech32, isEmptyString } from "./stringUtils";
import { toast } from "react-toastify";
import { ToastId } from "@src/constants/toastId";
import environments from "@src/configs/environments";
import { DRepMetadata, DRepType, TokenType } from "@src/store/drep/types";
import {
  IMetadata,
  MetadataBody,
  imageObject,
  imageObjectFieldType,
} from "@src/models/dtos/metadata";
import { isEmpty } from "lodash";
import { getFieldValue } from "./metadataUtils";

export async function getDRepIdFromCip30Wallet(wallet: CIP30Wallet) {
  const networkId = await wallet.networkId();
  const dRepPubKey = (await wallet.cip95?.getPubDRepKey()) || "";
  const dRepPubKeyHash = blake2bHash(dRepPubKey);
  console.log("DRep PubKey Hash: ", dRepPubKeyHash);

  if (networkId.toString() != environments.NETWORK_ID) {
    console.log(networkId.toString());

    const isTestnet = networkId === 0;
    const toastMessage = `You're connected to the ${isTestnet ? "testnet" : "mainnet"}. Please switch your wallet to the ${isTestnet ? "mainnet" : "testnet"} or choose a different wallet.`;

    toast.error(toastMessage, {
      toastId: ToastId.ERROR_TOAST,
    });
    return;
  }

  if (dRepPubKey == "") {
    toast.error("Your wallet is not CIP-95 compatible", {
      toastId: ToastId.ERROR_TOAST,
    });
    return;
  }
  // prefix 22 is for making drepId cip-129 compatible for metadata validation
  const hexDRepId = "22" + dRepPubKeyHash;
  return hexToBech32(hexDRepId);
}

export function lovelaceToAda(lovelace: number) {
  const Ada = lovelace / 1000000;
  return Math.floor(Ada);
}

export function getTokenFeeInAda(
  feeList: (number | bigint)[],
  tokenName: string,
): string {
  const fee =
    tokenName.length >= feeList.length
      ? feeList[feeList.length - 1]
      : feeList[tokenName.length - 1];
  const feeBigInt = typeof fee === "bigint" ? fee : BigInt(fee);
  const scaledFee = (feeBigInt * BigInt(100)) / BigInt(1_000_000);
  const roundedFee =
    scaledFee +
    ((feeBigInt * BigInt(100)) % BigInt(1_000_000) > 0 ? BigInt(1) : BigInt(0));
  return (Number(roundedFee) / 100).toFixed(2);
}

export function formatAda(ada: number): string {
  if (ada >= 1_000_000_000) {
    return `${(ada / 1_000_000_000).toFixed(2)}B`;
  } else if (ada >= 1_000_000) {
    return `${(ada / 1_000_000).toFixed(2)}M`;
  } else if (ada >= 1_000) {
    return `${(ada / 1_000).toFixed(2)}K`;
  } else {
    return ada.toString();
  }
}

export const getDRepDetails = async ({
  dRepId,
  dRepName,
}: {
  dRepId?: string;
  dRepName?: string;
}) => {
  let dRep: DRepType | null = null;
  let metadata: IMetadata | null = null;
  let token: TokenType | null = null;
  try {
    const url = dRepId
      ? `${environments.INTERNAL_API_URL}/drep?id=${dRepId}`
      : `${environments.INTERNAL_API_URL}/drep?name=${dRepName}`;
    const response = await fetch(url, {
      headers: {
        "Cache-Control": "no-cache",
      },
      next: { revalidate: 0 },
    });

    if (response.ok) {
      const responseJson: DRepMetadata = await response.json();
      dRep = responseJson.dRep;
      token =
        responseJson.token && responseJson.token.length !== 0
          ? responseJson.token[0]
          : null;
      if (dRep && !("drep_details" in dRep) && dRep.url && dRep.dataHash) {
        try {
          const metadataUrl = `${environments.METADATA_API_URL}/api/metadata?url=${dRep.url}&hash=${dRep.dataHash}`;
          const metadataResponse = await fetch(metadataUrl, {
            headers: {
              "Cache-Control": "invalidate",
            },
            next: { revalidate: 0 },
          });

          if (metadataResponse.ok) {
            const metadataJsonResponse = await metadataResponse.json();
            const metadataJsonBody: MetadataBody =
              metadataJsonResponse.metadata["body"];
            if (!isEmpty(metadataJsonBody.givenName as string)) {
              metadata = metadataJsonResponse.metadata;
            }
          } else {
            console.log(
              url,
              metadataResponse.status,
              await metadataResponse.text(),
            );
          }
        } catch (error) {
          console.error("Error fetching metadata:", error);
        }
      }
    } else {
      console.log(url, response.status, await response.text());
    }
    return {
      dRep,
      metadata,
      token,
    };
  } catch (error) {
    console.error("Error fetching dRep details:", error);
    return {
      dRep,
      metadata,
      token,
    };
  }
};

export async function getCanvas(name: string) {
  const url = `${environments.INTERNAL_API_URL}/canvas?text=${name}`;
  const response = await fetch(url, {
    headers: {
      "Cache-Control": "no-cache",
    },
    next: { revalidate: 0 },
  });

  if (response.ok) {
    const imageUrl = await response.json();
    return imageUrl;
  } else {
    console.error(
      "Error fetching canvas image:",
      response.status,
      await response.text(),
    );
    return null;
  }
}

export function getImageUrlString(image: any) {
  let imageUrl;
  const isImageObject = (image: unknown): image is imageObject => {
    return (
      typeof image === "object" &&
      image !== null &&
      (image as imageObject)["@type"] === "ImageObject" &&
      typeof (image as imageObject).contentUrl === "string"
    );
  };
  const isImageObjectFieldType = (
    image: unknown,
  ): image is imageObjectFieldType => {
    return (
      typeof image === "object" &&
      image !== null &&
      typeof (image as imageObjectFieldType)["@value"] === "object" &&
      (image as imageObjectFieldType)["@value"] !== null &&
      (image as imageObjectFieldType)["@value"]["@type"] === "ImageObject" &&
      typeof (image as imageObjectFieldType)["@value"].contentUrl === "string"
    );
  };
  if (isImageObject(image)) {
    imageUrl = image.contentUrl;
  } else if (isImageObjectFieldType(image)) {
    imageUrl = image["@value"].contentUrl;
  } else {
    imageUrl = getFieldValue(image);
  }
  if (imageUrl && !isEmptyString(imageUrl)) {
    return imageUrl;
  }
}
